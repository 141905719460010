import revive_payload_client_4sVQNw7RlN from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_LDumGYo2KH from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_W86eSGFqyZ from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_8SbxDRbG6Y from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import maska_UHaKf2z1iQ from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/plugins/maska.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  plugin_client_W86eSGFqyZ,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  maska_UHaKf2z1iQ
]