import { default as manufacturerzkilSb7Fm8Meta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/manufacturer.vue?macro=true";
import { default as materialConsumptionCutokTOkCkdlMMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/materialConsumptionCut.vue?macro=true";
import { default as materialConsumptionEdgeun2B0pZRaSMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/materialConsumptionEdge.vue?macro=true";
import { default as payUxsTDlD2FxMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/pay.vue?macro=true";
import { default as payBuildJTFlyqEre3Meta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/payBuild.vue?macro=true";
import { default as stockwYFOiKpKMuMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/stock.vue?macro=true";
import { default as indexWFUTe1ztG5Meta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/build/pages/build/index.vue?macro=true";
import { default as indexqK35DqQjv6Meta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/calc/pages/calc/index.vue?macro=true";
import { default as indexXkSOlR6nTOMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/crm/pages/crm/index.vue?macro=true";
import { default as dashboardJlHKQ9EsLYMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/pages/dashboard.vue?macro=true";
import { default as editsMv3xTr4ASMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/[id]/edit.vue?macro=true";
import { default as archivez9iqhlY3NeMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/archive.vue?macro=true";
import { default as createvrCNvXOF4gMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/create.vue?macro=true";
import { default as indexrGlXyqjf35Meta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/index.vue?macro=true";
import { default as indexlXIUXbUvMOMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/productsKoroba/pages/dictionary/productsKoroba/index.vue?macro=true";
import { default as indexFDzzVue2clMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/productsMetal/pages/dictionary/productsMetal/index.vue?macro=true";
import { default as editLfHjMY9HQQMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/[id]/edit.vue?macro=true";
import { default as archivenBnQynP3aLMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/archive.vue?macro=true";
import { default as create1RYUAJUvrlMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/create.vue?macro=true";
import { default as indexhLPQUuOhgPMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/index.vue?macro=true";
import { default as editGoQqIt95QIMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/[id]/edit.vue?macro=true";
import { default as archiveW37QQHVS6hMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/archive.vue?macro=true";
import { default as createzhYAZofOPfMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/create.vue?macro=true";
import { default as indexnqlWflp5cBMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/index.vue?macro=true";
import { default as indexTJUEsqeGiBMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocksKoroba/pages/dictionary/stocksKoroba/index.vue?macro=true";
import { default as indexcQUqJUXFKkMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocksMetal/pages/dictionary/stocksMetal/index.vue?macro=true";
import { default as indexCqSiM11OHaMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/pages/index.vue?macro=true";
import { default as createRiQohjMfpiMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/manufacturers/pages/manufacturers/create.vue?macro=true";
import { default as indexEKnU7SijMuMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/profile/pages/profile/source/index.vue?macro=true";
import { default as editArch4BBzXGMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/roles/pages/roles/[id]/edit.vue?macro=true";
import { default as createfHV4Y9zcx8Meta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/roles/pages/roles/create.vue?macro=true";
import { default as indexb0MRvz9HhYMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/roles/pages/roles/index.vue?macro=true";
import { default as indexZu7QVcOFTjMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/stock/pages/stock/index.vue?macro=true";
import { default as indexYgGvQEw8zZMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/tasks/pages/tasks/index.vue?macro=true";
import { default as editWkU3aBcTkHMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/[id]/edit.vue?macro=true";
import { default as archiveTwCde3Qq1SMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/archive.vue?macro=true";
import { default as createg7sXocU77pMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/create.vue?macro=true";
import { default as indexsa1IRmthsyMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/index.vue?macro=true";
import { default as indexHgbX7rndVPMeta } from "/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/workPlans/pages/workPlans/[id]/index.vue?macro=true";
export default [
  {
    name: "analytics-manufacturer",
    path: "/analytics/manufacturer",
    meta: manufacturerzkilSb7Fm8Meta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/manufacturer.vue").then(m => m.default || m)
  },
  {
    name: "analytics-materialConsumptionCut",
    path: "/analytics/materialConsumptionCut",
    meta: materialConsumptionCutokTOkCkdlMMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/materialConsumptionCut.vue").then(m => m.default || m)
  },
  {
    name: "analytics-materialConsumptionEdge",
    path: "/analytics/materialConsumptionEdge",
    meta: materialConsumptionEdgeun2B0pZRaSMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/materialConsumptionEdge.vue").then(m => m.default || m)
  },
  {
    name: "analytics-pay",
    path: "/analytics/pay",
    meta: payUxsTDlD2FxMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/pay.vue").then(m => m.default || m)
  },
  {
    name: "analytics-payBuild",
    path: "/analytics/payBuild",
    meta: payBuildJTFlyqEre3Meta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/payBuild.vue").then(m => m.default || m)
  },
  {
    name: "analytics-stock",
    path: "/analytics/stock",
    meta: stockwYFOiKpKMuMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/analytics/pages/analytics/stock.vue").then(m => m.default || m)
  },
  {
    name: "build",
    path: "/build",
    meta: indexWFUTe1ztG5Meta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/build/pages/build/index.vue").then(m => m.default || m)
  },
  {
    name: "calc",
    path: "/calc",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/calc/pages/calc/index.vue").then(m => m.default || m)
  },
  {
    name: "crm",
    path: "/crm",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/crm/pages/crm/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardJlHKQ9EsLYMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-products-id-edit",
    path: "/dictionary/products/:id()/edit",
    meta: editsMv3xTr4ASMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-products-archive",
    path: "/dictionary/products/archive",
    meta: archivez9iqhlY3NeMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/archive.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-products-create",
    path: "/dictionary/products/create",
    meta: createvrCNvXOF4gMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/create.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-products",
    path: "/dictionary/products",
    meta: indexrGlXyqjf35Meta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/products/pages/dictionary/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-productsKoroba",
    path: "/dictionary/productsKoroba",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/productsKoroba/pages/dictionary/productsKoroba/index.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-productsMetal",
    path: "/dictionary/productsMetal",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/productsMetal/pages/dictionary/productsMetal/index.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-sheetFormats-id-edit",
    path: "/dictionary/sheetFormats/:id()/edit",
    meta: editLfHjMY9HQQMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-sheetFormats-archive",
    path: "/dictionary/sheetFormats/archive",
    meta: archivenBnQynP3aLMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/archive.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-sheetFormats-create",
    path: "/dictionary/sheetFormats/create",
    meta: create1RYUAJUvrlMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/create.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-sheetFormats",
    path: "/dictionary/sheetFormats",
    meta: indexhLPQUuOhgPMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/index.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-stocks-id-edit",
    path: "/dictionary/stocks/:id()/edit",
    meta: editGoQqIt95QIMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-stocks-archive",
    path: "/dictionary/stocks/archive",
    meta: archiveW37QQHVS6hMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/archive.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-stocks-create",
    path: "/dictionary/stocks/create",
    meta: createzhYAZofOPfMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/create.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-stocks",
    path: "/dictionary/stocks",
    meta: indexnqlWflp5cBMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-stocksKoroba",
    path: "/dictionary/stocksKoroba",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocksKoroba/pages/dictionary/stocksKoroba/index.vue").then(m => m.default || m)
  },
  {
    name: "dictionary-stocksMetal",
    path: "/dictionary/stocksMetal",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/dictionaries/stocksMetal/pages/dictionary/stocksMetal/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexCqSiM11OHaMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "manufacturers-create",
    path: "/manufacturers/create",
    meta: createRiQohjMfpiMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/manufacturers/pages/manufacturers/create.vue").then(m => m.default || m)
  },
  {
    name: "profile-source",
    path: "/profile/source",
    meta: indexEKnU7SijMuMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/profile/pages/profile/source/index.vue").then(m => m.default || m)
  },
  {
    name: "roles-id-edit",
    path: "/roles/:id()/edit",
    meta: editArch4BBzXGMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/roles/pages/roles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "roles-create",
    path: "/roles/create",
    meta: createfHV4Y9zcx8Meta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/roles/pages/roles/create.vue").then(m => m.default || m)
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexb0MRvz9HhYMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/roles/pages/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "stock",
    path: "/stock",
    meta: indexZu7QVcOFTjMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/stock/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/tasks/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editWkU3aBcTkHMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "users-archive",
    path: "/users/archive",
    meta: archiveTwCde3Qq1SMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/archive.vue").then(m => m.default || m)
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: createg7sXocU77pMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexsa1IRmthsyMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/users/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "workPlans-id",
    path: "/workPlans/:id()",
    meta: indexHgbX7rndVPMeta || {},
    component: () => import("/var/www/raspil12_dev_usr/data/www/raspil12-dev.ffa.su/modules/workPlans/pages/workPlans/[id]/index.vue").then(m => m.default || m)
  }
]